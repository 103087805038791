import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Slider from "react-slick";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import Lightbox from "react-image-lightbox";
import {isMobile} from "react-device-detect";
import Image from "next/image";

// It's not allowed any more to have CSS from node_modules packages - this used to work
// for NextJS components import.
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "react-image-lightbox/style.css";

const stylesHOC = (theme) => ({
  rootCarousel: {
    minHeight: 100,
    [theme.breakpoints.up("md")]: {
      maxHeight: '550px',
    },
    lineHeight: 0,
    position: 'relative',
    "& img:hover": {
      cursor: 'pointer'
    },
    "& img": {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      backgroundColor: 'white',
      // minHeight: '150px !important',
      objectPosition: 'center center',
      margin: '0 !important',
      [theme.breakpoints.up("md")]: {
        minHeight: '280px !important',
        // border: 'solid 10px red',
      },
    },
    "& .two-up-slider .slick-list .slick-track .slick-current > div": {
      [theme.breakpoints.up("md")]: {

        borderBottom: 'none !important',
        borderRight: 'solid 10px white !important',
        height: 'auto',
      },
    },
    "& .two-up-slider .slick-slide > div > div": {
      [theme.breakpoints.up("md")]: {

        height: 'auto',

      },
    },
    "& .two-up-slider .slick-slide": {
      [theme.breakpoints.up("md")]: {
        // height: '450px !important',
        overflow: 'hidden'
      },
      "& img, & span": {
        [theme.breakpoints.up("md")]: {
          minHeight: '550px !important',
          objectFit: 'cover',
          objectPosition: 'center bottom',
          // backgroundColor: '#fafafa',
        },
      },
      "& span": {
        minWidth: '100%',
        maxWidth: '100%',
      },
    },
  },
  slickSlider: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto!important',
    position: 'relative',
    display: 'block',
    overflow: 'hidden'
  },
  slickSliderInner: {},
  slickNext: {
    right: '0',
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },
  },
  slickPrev: {
    left: '0',
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },
  },
  thumbnailSliderWrap: {
    minHeight: 130,
    [`@media only print`]: {
      minHeight: 0,
    },
  },
  slickImage: {
    minHeight: '400px',
  },
  slickImages: {},
  thumbImage: {},
  galleryImage: {},
  featuredImage: {},
  firstImage: {
    '& img': {
      [theme.breakpoints.up("md")]: {
        paddingRight: 'calc(33% + 1rem) !important',
      },
    },
    "& + .undefined img": {
      minHeight: '280px !important',
      marginTop: '0 !important',
    },
    "& .featured": {
      firstImage: {
        display: 'none !important',
      },
    },
  },
  printImage: {
    display: 'none',
    ['@media only print']: {
      display: 'block',
    }
  },
  slickArrow: {
    top: '36%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    zIndex: 20,
    border: 'none',
    padding: '0',
    [theme.breakpoints.up("sm")]: {
      top: '50%'
    },
    "&:hover": {
      cursor: 'pointer'
    }
  }
});

const ConditionalWrapper = ({ condition, wrapper, children }) => condition
  ? wrapper(children)
  : children;

function CarouselHOC(props) {
  if (!props.slides && !props.children) {
    return null;
  }
  let {
    classes, slides, showLight,
    // if empty - always show thumbs, if not empty - hide thumbs responsively:
    showThumb,
    overlay,
    // if displaying content, but want image thumbs:
    contentThumbs,
    featured,
    slickSett,
    slickNavSett,
    children,
    margin,
    marginUnit,
    courseCarousel,
    contentCarousel,
    linkOut,
  } = props;

  let mt,
    mr,
    mb,
    ml,
    unit;
  if (margin) {
    unit = marginUnit || 'px';
    ([mt, mr, mb, ml] = margin);
  }

  function SampleNextArrow(props) {
    const { onClick } = props;
    // const classes = useStyles();
    return (<div className={`${classes.slickNext} ${classes.slickArrow}`} onClick={onClick}><ChevronRightIcon /></div>);
  }
  function SamplePrevArrow(props) {
    const { onClick } = props;
    // const classes = useStyles();
    return (<div className={`${classes.slickPrev} ${classes.slickArrow}`} onClick={onClick}><ChevronLeftIcon /></div>);
  }

  // The slides will be images or content?
  const showContent = (children && !overlay) ? true : false;
  slides = showContent ? children : slides;

  const [activeSlide, setActiveSlide] = useState(0);
  const [photoIndex, setPhotoIndex] = useState({ ind: null, src: '' });
  const [slideNav, setSlideNav] = useState({ nav1: null, nav2: null });

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setSlideNav({ nav1: slider1.current, nav2: slider2.current });
  }, [slider1, slider2]);

  const slick_opts = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => {
      if (featured) {
        setActiveSlide(next);
      }
    },
    // mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: showThumb
            ? false
            : true,
          rows: 1,
          slidesPerRow: 1,
          speed: 600,
          autoplaySpeed: 5000,
          centerMode: true,
          centerPadding: "0px"
        }
      }
    ]
  };
  const slick_nav_opts = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false
  };

  slickSett = slickSett ? {...slick_opts, ...slickSett} : {...slick_opts, ...slickSett};

  slickNavSett = slickNavSett
    ? {
      ...slick_nav_opts,
      ...slickNavSett
    }
    : slick_nav_opts;

  function handleClickImage(e, ind, image) {
    if (showLight) {
      e && e.persist() && e.preventDefault();
      setPhotoIndex({ ind: ind, src: image.src });
    }
  }

  function handleCloseModal(e) {
    if (showLight) {
      e && e.persist() && e.preventDefault();
      setPhotoIndex({ ind: null, src: '' });
    }
  }

  return (
    <Box className={classes.rootCarousel}>
    
      {overlay && children}
      <div className={classNames(featured ? classes.slickSlider : '')}>
        <div className={classNames(classes.slickSliderInner, featured ? 'featured' : '', (featured && activeSlide == 0) ? 'active' : '')}>
          {featured &&
            // Featured slide on listing carousels
            <Hidden xsDown={true}>
              <div className={classes.firstImage}>
                <Box>
                  <Image
                    priority
                    placeholder="blur" blurDataURL="/images/blur.jpg"
                    width="870"
                    height="520"
                    layout="fill" className={classes.featuredImage} onClick={(e) => handleClickImage(e, 0, slides[0])} src={slides[0].src} alt={slides[0].imgAlt || 'Slide 1'} title={slides[0].imgTitle} />
                </Box>
              </div>
            </Hidden>
          }
       
          <ConditionalWrapper condition={margin} wrapper={children => <Box mt={`${mt}${unit}`} mr={`${mr}${unit}`} mb={`${mb}${unit}`} ml={`${ml}${unit}`}>{children}</Box>}>
            <div className={classes.slickImages}>
              <Slider {...slickSett} ref={slider1} asNavFor={slideNav.nav2}>
                {slides.map((val, ind) => {
                  // Only FEATURED layout should not include 1st image in Slider on desktop.
                  // On desktop 1st slide has modified markup. Show on mobile.
                  if (featured && ind === 0 && !isMobile) {
                    return null;
                  }
                  if (showContent) {
                    return (<div key={`imgs-${ind}`} className={classes.galleryImage}>
                      <div key={`content-${ind}`}>
                        {val}
                      </div>
                    </div>);
                  }
                  return (<div key={`imgs-${ind}`} className={`${classes.galleryImage} gallery-img`}>
                    <ConditionalWrapper style={{ position: 'relative', display: 'block' }} condition={linkOut && !showLight && val.href} wrapper={children => <a href={val.href} target={val.hreftarget}>{children}</a>}>
                      {contentCarousel &&
                      <Image priority layout="fixed" placeholder="blur" blurDataURL="/images/blur.jpg" width="900" height="480" className={classes.slickImage} src={val.src} alt={val.imgAlt || `Slide ${ind + 1}`} title={val.imgTitle} onClick={(e) => handleClickImage(e, ind, val)} />
                      }
                      {/* Small gallery images */}
                      {!courseCarousel && !contentCarousel &&
                        <Image priority layout="fixed" placeholder="blur" blurDataURL="/images/blur.jpg" width="450" height="280" className={classes.slickImage} src={val.src} alt={val.imgAlt || `Slide ${ind + 1}`} title={val.imgTitle} onClick={(e) => handleClickImage(e, ind, val)} />
                      }
                      {/* Golf pages images */}
                      {courseCarousel &&
                        <Image layout="responsive" sizes='75vw' width='1200' height='550' className={classes.slickImage} src={val.src} alt={val.imgAlt || `Slide ${ind + 1}`} title={val.imgTitle} onClick={(e) => handleClickImage(e, ind, val)} />
                      }
                    </ConditionalWrapper>
                  </div>);
                })
                }
              </Slider>

              <ConditionalWrapper condition={(showThumb !== undefined && showThumb !== true && showThumb)} wrapper={children => <Hidden {...showThumb}>{children}</Hidden>}>
                {
                  (showThumb !== undefined && showThumb) && <div className={classes.thumbnailSliderWrap}>
                    <Slider className={classNames('thumb-nav')} {...slickNavSett} ref={slider2} asNavFor={slideNav.nav1}>
                      {
                        (
                          showContent && contentThumbs
                            ?.length > 0) && contentThumbs.map((val, ind) => {
                              return (<div key={`imgs-${ind}`} className={classes.thumbImage}>
                                <Image layout="responsive" sizes="50vw" width="450" height="280" className={classes.slickThumbnail} src={val.src} alt={val.imgAlt} title={val.imgTitle} />
                              </div>);
                            })
                      }
                      {
                        (showContent === undefined || !showContent) && slides.map((val, ind) => {
                          // Only FEATURED layout should not include 1st image in Slider on desktop.
                          // On desktop 1st slide has modified markup.
                          if (featured && ind == 0 && !isMobile) {
                            return null;
                          }
                          return (<div key={`imgs-${ind}`} className={classes.thumbImage}>
                            <Image layout="responsive" width="100" height="100" className={classes.slickThumbnail} src={val.src} alt={val.imgAlt} title={val.imgTitle} />
                          </div>)
                        })
                      }
                    </Slider>
                  </div>
                }
                {(showThumb !== undefined && showThumb) && null}
              </ConditionalWrapper>

              {(showLight && photoIndex?.src && !isNaN(photoIndex?.ind)) &&
                <Lightbox mainSrc={photoIndex.src} onCloseRequest={(e) => handleCloseModal(e)} nextSrc={slides[(photoIndex.ind + 1) % slides.length].src} prevSrc={slides[(photoIndex.ind + slides.length - 1) % slides.length].src}
                  onMovePrevRequest={() => setPhotoIndex({
                    ind: (photoIndex.ind + slides.length - 1) % slides.length,
                    src: slides[(photoIndex.ind + slides.length - 1) % slides.length].src
                  })
                  }
                  onMoveNextRequest={() => setPhotoIndex({
                    ind: (photoIndex.ind + 1) % slides.length,
                    src: slides[(photoIndex.ind + 1) % slides.length].src
                  })
                  }
                />
              }
            </div>
          </ConditionalWrapper>
        </div>
      </div>
      <Box className={classes.printImage}>
                  <Image
                    priority
                    width="870"
                    height="520"
                    layout="fill" src={slides[0].src} alt={slides[0].imgAlt || 'Slide 1'} title={slides[0].imgTitle} />
                </Box>
    </Box>);
}
export default withStyles(stylesHOC, { withTheme: true })(CarouselHOC);
