import React, {Fragment, useContext, useEffect} from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {pageview} from "@/helpers/gtm";
import CurrentPageContext from "@/components/context/CurrentPageData";
import Script from 'next/script';

const HtmlHead = ({metatags}) => {
  const sitewideCtx = useContext(CurrentPageContext); // sitewideCtx?.site can be "baywoods" or "potnets"
  // Define structured data
  const businessStructuredDataBaywoods = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Baywood Greens",
    "image": `${process.env.NEXT_PUBLIC_FRONT_URL}/assets/baywood-greens-logo.svg`,
    "@id": "",
    "url": `${process.env.NEXT_PUBLIC_FRONT_URL}`,
    "telephone": "+13029456280",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "32851 Greens Way",
      "addressLocality": "Long Neck",
      "addressRegion": "DE",
      "postalCode": "19966",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 38.64424,
      "longitude": -75.18767
    },
    "sameAs": [
      "https://www.facebook.com/baywoodgreenscommunity",
      "https://www.pinterest.com/baywoodgreens",
      "https://www.youtube.com/user/baywoodgreensgolf",
      "https://www.instagram.com/baywoodclubhouse/"
    ]
  };
  const businessStructuredDataPotnets = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Pot-Nets",
    "image": `${process.env.NEXT_PUBLIC_FRONT_URL}/assets/pot-nets.svg`,
    "@id": "",
    "url": `${process.env.NEXT_PUBLIC_FRONT_URL}`,
    "telephone": "+13029459300",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "34026 Anna's Way",
      "addressLocality": "Long Neck",
      "addressRegion": "DE",
      "postalCode": "19966",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 38.62879,
      "longitude": -75.15913
    },
    "sameAs": [
      "https://www.facebook.com/potnets",
      "https://instagram.com/pot_nets",
      "https://www.youtube.com/channel/UCDVcqlI9MVpbYvIbBSliOfw",
    ]
  };

  // @see: https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    }
  }, [router.events]);

  return (
    <>
      <Script type="application/ld+json" key={`schema-json`} id={`schema-json`}
              dangerouslySetInnerHTML={{ __html: JSON.stringify(sitewideCtx?.site == 'potnets' ? businessStructuredDataPotnets : businessStructuredDataBaywoods, null, "\t")}}>
      </Script>
      <Head key={`head-${sitewideCtx?.site}`}>
        <meta name="viewport"
              content="width=device-width, minimum-scale=1, initial-scale=1"
              key="viewport"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" key="xua"/>
        <link rel="shortcut icon" href="/favicon.ico"
              type="image/vnd.microsoft.icon" key="shortcut"/>

        {Array.isArray(metatags) && metatags.map((tag, i) => {
          let type = tag[0]['#tag'];
          return (
            <Fragment key={`${tag}-${i}`}>
              {type == 'link' && <link rel={tag[0]['#attributes'].rel}
                                       href={tag[0]['#attributes'].href}
                                       key={tag[1]}/>}
              {type == 'meta' && tag[1] == 'title' &&
              <title key="title">{tag[0]['#attributes'].content}</title>}
              {type == 'meta' && tag[1] != 'title' &&
              <meta name={tag[0]['#attributes']?.property || tag[1]} content={tag[0]['#attributes'].content}
                    key={tag[1]}/>}
            </Fragment>
          );
        })}
      </Head>
    </>
  );
};

export default HtmlHead;
