import {
  createTheme
} from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1400,
      xl: 1600
    },
  },
  name: 'Baywoods',
  themeName: 'Baywoods',
  palette: {
    statusActive: '#1773a4',
    primary: {
      main: '#3C8047',
      contrastText: '#ffffff',
      light: '#6A9471',
    },
    secondary: {
      main: '#847251',
      dark: '#AC9C81',
      contrastText: '#ffffff',
    },
    text: {
      main: '#f44336',
      secondary: '#8D8D8D',
      disabled: '#333333',
    },
    error: {
      main: "#e26126",
      contrastText: "#000000",
    },
    success: {
      main: '#368743',
      light: '#42B5B5',
      lighter: '#FEF0D3',
    },
    warning: {
      main: '#E28C26',
      light: '#ffee88',
      dark: '#cdb108',
    },
    info: {
      main: '#326398',
      light: '#0071B3',
    },
    grey: {
      100: '#FFFFFF',
      200: '#F4F4F4',
      300: '#CFCFCF',
      700: '#3F3F3F',
      800: '#333333',
      900: '#000000',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    body: {
      fontSize: 4,
      color: '#f0f0f0',
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Lato', sans-serif",
    h1: {
      fontSize: "3.125rem",
      fontFamily: 'Playfair Display',
      fontWeight: 300,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.75rem',
      },
    },
    h2: {
      fontSize: "3rem",
      fontFamily: 'Playfair Display',
      fontWeight: 300,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.5rem',
      },
    },
    h3: {
      fontSize: "2.5rem",
      fontFamily: 'Playfair Display',
      fontWeight: 300,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: "2rem",
      fontFamily: 'Playfair Display',
      fontWeight: 300,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.85rem',
      },
    },
    h5: {
      fontSize: "1.75rem",
      fontFamily: 'Playfair Display',
      fontWeight: 300,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.75rem',
      },
    },
    h6: {
      fontSize: "1.125rem",
      fontFamily: 'Playfair Display',
    },
    body1: {
      fontSize: "1.5rem",
      marginBottom: "1rem",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1rem',
      },
      "& a": {
        fontWeight: 700,
      },
    },
    body2: {
      fontSize: "1.25rem",
    },
    srOnly: {
      color: 'black',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiFormControl: {
      root: {
        height: '56px',
        fontSize: '1rem',
      },
    },
    MuiInputBase: {
      root: {
        height: '56px',
        fontSize: '1rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1rem"
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'center',
        // justifyContent: 'flex-start !important',
        [defaultTheme.breakpoints.down("sm")]: {
          justifyContent: 'space-between !important',
        },
          "& li ": {
            listStyleType: 'none !important',
            "& a": {

          },
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#000000',
        fontSize: 24,
        padding: '1rem !important',
        display: 'inline-block !important',
        backgroundColor: "#fafafa",
        height: 'auto !important',
        margin: '0 .5rem',
        selected: {
          backgroundColor: 'transparent !important',
        },
        "&$outlined": {
          border: 'none',
        },
        outlinedPrimary: {
          border: 'none !important',
          '&:hover': {
            color: '#00235D',
          },
        },
        "&$selected": {
          color: '#406746 !important',
          border: 'none !important',
        }
      },

    },
    textCenter: {
      textAlign: 'center',
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          maxWidth: '100vw',
          overflowX: 'hidden',
          backgroundColor: '#ffffff',
          '& .MuiFormLabel-root, & .MuiFormHelperText-root': {
            color: 'black',
          },
          '& .MuiSlider-markLabel': {
            color: '#444444',
          },
          "& .card-content": {
            padding: '.25rem',
          },
          "& hr": {
            width: '100%',
          },
          "& ul li": {
            listStyle: 'disc',
          },
          "& img": {
            maxWidth: '100%',
            height: 'auto',
          },
          "& .serif": {
            fontFamily: 'Playfair Display',
            textTransform: 'none',
          },
          "& h1, & h2, & h3, & h4, & h5, & h6": {
            fontFamily: 'Playfair Display',
          },
          "& .btn": {
            backgroundColor: '#3C8047',
            padding: '12.5px 16.4px',
            fontSize: '20px',
            display: 'inline-block',
            color: 'white',
            textTransform: 'uppercase',
            textDecoration: 'none',
            fontWeight: 400,
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          "& .btn:hover": {
            backgroundColor: '#2a5931',
          },
          "& .btn.sm": {
            padding: '4px 10px',
            fontSize: '0.8125rem',
          },
          "& .btn.lg": {
            padding: '2.2rem 2.6rem',
            fontSize: '28px',
            lineHeight: '1.2',
          },

        },

        code: {
          backgroundColor: "#f1f1f1",
          marginTop: '1rem',
          padding: '1rem',
          fontFamily: "'Lato', sans-serif",
          display: 'block',
        },
        lato: {
          fontFamily: "'Lato', sans-serif",
        },
        '.box-shadow': {
          boxShadow: '0 0 1px 1px white inset, 0 23px 21px -33px #000',
          backgroundColor: 'white !important',
          padding: '1rem 1rem 0',
          margin: '2.1rem 0',
          border: 'solid thin black',
        },
        '.responsive-iframe-container iframe': {
          [defaultTheme.breakpoints.down("md")]: {

            width: '100% !important',
            height: 'auto',
          },
        },

        '.responsive-iframe-container': {
          [defaultTheme.breakpoints.down("md")]: {
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingTop: '56.25%',
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            },
          },
        },
        ".content-css span, .content-css p": {
          fontSize: 20,
        },
        ".expandAnchor": {
          fontSize: 12,
          textDecoration: 'underline',
        },
        ".wysiwyg-2-col, .wysiwyg-3-col": {
          display: 'flex',
          margin: '1rem 0',
          flexDirection: 'column',
          "@media (min-width: 1025px)": {
            flexDirection: 'row',
          },
        },
        ".wysiwyg-2-col .col": {
          width: '100%',
          "@media (min-width: 1025px)": {
            width: '50%',
          },
        },
        ".wysiwyg-3-col .col": {
          width: '100%',
          "@media (min-width: 1025px)": {
            width: '33%',
          },
        },
        ".lato": {
          fontFamily: "'Lato', sans-serif !important",
        },
        ".khand": {
          fontFamily: "'Khand', sans-serif !important",
        },
        ".wysiwyg-block.bg-color-gray": {
          backgroundColor: '#F8F8F8',
          padding: '1rem',
        },
        ".wysiwyg-block.bg-color-tan": {
          backgroundColor: '#F1EBDD',
          padding: '1rem',
        },
        ".wysiwyg-block.bg-color-dark-gray": {
          backgroundColor: '#212529',
          padding: '1rem',
          color: 'white',
        },
        ".wysiwyg-block.bg-color-dark-gray *": {
          color: 'white',
        },
        ".wysiwyg-icon-col": {
          display: 'flex',
          alignItems: 'center',
          margin: '1rem 0',
        },
        ".wysiwyg-icon-col .col:first-child": {
          minWidth: '50px',
        },
        ".wysiwyg-icon-col .col + .col": {
          marginLeft: '2rem',
          lineHeight: '1.2',
        },
        ".ctaWrapper": {
          backgroundColor: 'black',
        },
        // ".slick-track": {
        //   maxHeight: 400,
        // },
        ".breadcrumb-list": {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'start',
          "& li": {
            width: 'auto',
            lineHeight: 1.2,
            fontSize: '.75rem',
            padding: '0 0.3rem',
            "&:not(:last-child):after": {
              content: "' /'",
              paddingLeft: ".7rem",
            },
            "& a": {
              fontSize: '.75rem',
            }
          }
        },
        ".listingCta svg": {
          fill: '#3c8047',
        },
        ".para--testimonials > div > div > div": {
          maxHeight: '2000px'
        },
        ".testimonial-carousel > div > div > div": {
          maxHeight: 2000,
        },
        ".courseCarousel > div": {
          maxHeight: 'unset',
        },
        ".node-type-house_listing .slick-slider .slick-track, .node-type-house_listing .slick-slider .slick-list": {
          [defaultTheme.breakpoints.down("sm")]: {
            maxHeight: '400px',
          },
        },
      },
    },
    // Style sheet name ⚛️
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: "#333333",
        color: 'white',

        "&:hover": {
          backgroundColor: "#111111",
        }
      },
      root: {
        fontSize: '20px',
        padding: '12.5px 16.4px',
      },
      sizeLarge: {
        fontSize: 28,
        lineHeight: '1.2',
        padding: '2.2rem 2.6rem',
      },

    },
    MuiTab: {
      root: {
        fontSize: 18,
        marginRight: 8,
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: 39,
          marginRight: 40,
          maxWidth: 1000,
        },
        whiteSpace: 'inherit',
        overflow: 'visible',
        fontWeight: 300,
        color: '#5A646F !important',
        opacity: '1 !important',
        paddingLeft: 0,
        "& :hover": {
          fontWeight: 600,
        },
        "& span": {
          alignItems: 'flex-start',
        },
        "&$selected": {
          fontWeight: 800,
          "& :hover": {
            fontWeight: 800,
          }
        },

      },
    },
    MuiBreadcrumbs: {
      root: {
        fontSize: 12,
        padding: '1rem 0',
      },
      li: {
        "& p": {
          fontSize: 12,
          display: 'inline',
        },

        "& a": {
          color: '#3C8047',
        },
      },
    },

    MuiAccordionSummary: {
      content: {
        "& div": {
          color: '#000000',
          textAlign: 'center',
          fontFamily: 'Playfair Display',
          fontSize: '28px',
          lineHeight: '37.32px',
        },
      },
      expandIcon: {
        backgroundColor: '#3C8047',
        borderRadius: 0,
        padding: '6px',
        margin: '0 0 0 10px',
        color: 'white',
        transform: 'rotate(0) !important',

        "&:hover": {
          backgroundColor: '#3C8047',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        "& div": {
          "& div": {
            fontSize: '18px',
            lineHeight: '21.6px',
          },
        },
      },
    },
  },
};

export const themeExtender = createTheme(theme);

export default theme;
