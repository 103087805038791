import React, { useContext } from 'react';
import CurrentPageContext from '@/components/context/CurrentPageData';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {useRouter} from 'next/router';
import Header from "@/components/03_organisms/GlobalHeader/Header";
import GlobalFooter from '@/components/03_organisms/GlobalFooter/Footer';
import PotnetsHeader from "@/components/03_organisms/GlobalHeader/HeaderPotnets";
import PotnetsFooter from '@/components/03_organisms/GlobalFooter/FooterPotnets';

const ConditionalWrapper = ({condition, wrapper, children}) =>
  condition == 'contained' ? wrapper(children) : children;

const GlobalLayout = (props) => {
  const router = useRouter();
  const {menu, footermenu, blocks, layout, children} = props;
  const sitewideContext = useContext(CurrentPageContext);

  return (
    <>
    {!router?.pathname?.includes('/thank-you-contacting-baywood-greens') && 
      <header id="site-header">
        {sitewideContext?.site == 'baywoods' &&
          <Header menu={menu}></Header>
        }
        {sitewideContext?.site == 'potnets' &&
          <PotnetsHeader menu={menu}></PotnetsHeader>
        }
      </header>
    }

      <ConditionalWrapper condition={layout} wrapper={children =>
        <Container><Box py="2rem">{children}</Box></Container>}
      >
        <>
          {Object.keys(blocks).length > 0 &&
            <div className="reactjs-sidebar">
            {
              Object.entries(blocks).map(([key, block]) => {
                if (block.region == 'react_sidebar' && block.data && block.data.body && block.data.body[0]) {
                  return (<div className={`block-id-${key}`}
                               key={`react-block-sidebar-${key}`}
                               dangerouslySetInnerHTML={{__html: block.data.body[0]['#text']}}></div>);
                }
                else if (block.region == 'react_sidebar' && block.data) {
                  return (<div className={`block-id-${key}`}
                               key={`react-block-sidebar-${key}`}
                               dangerouslySetInnerHTML={{__html: block.data}}></div>);
                }
              })
            }
          </div>
          }
          <div className="content-main-wrapper" id="main-content">
            <Container>
              {children}
            </Container>
          </div>
        </>
      </ConditionalWrapper>

      {!router?.pathname?.includes('/thank-you-contacting-baywood-greens') && 
      <>
      {sitewideContext?.site == 'baywoods' &&
        <GlobalFooter menu={footermenu}/>
      }
      {sitewideContext?.site == 'potnets' &&
        <PotnetsFooter menu={footermenu}/>
      }
      </>
    }
      </>
  );
};

GlobalLayout.propTypes = {
  menu: PropTypes.object,
  blocks: PropTypes.object,
};

GlobalLayout.defaultProps = {
  blocks: {},
};

export default GlobalLayout;
